import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div
          id="popup-text"
          className="popup-text"
          style={{
            width: "575px",
            height: "310px",
            "background-color": "#f2f2f2",
            margin: "0 auto",
            "-webkit-text-align": "center",
            "text-align": "center",
            "font-size": "17px",
            padding: "10px"
          }}
        >
          <p className="rtecenter">
            Migraleve
            <sup
              style={{
                "font-size": "0.6em"
              }}
            >
              TM
            </sup>{" "}
            Pink, and Migraleve
            <sup
              style={{
                "font-size": "0.6em"
              }}
            >
              TM
            </sup>{" "}
            packs containing Pink Tablets and Yellow Tablets are now available.
          </p>
          <p>Thank you for your patience.</p>
          <p className="rtecenter">
            Many Thanks,
            <br />
            The Migraleve
            <sup
              style={{
                "font-size": "0.6em"
              }}
            >
              TM
            </sup>{" "}
            team
          </p>
        </div>
      </div>
    );
  }
}

export default Page;
